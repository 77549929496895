import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const SimilarArticles = ({ articlesList, title }) => {
  return (
    <section className="related-posts">
      <h2 className="related-posts__title">{title}</h2>
      <div className="articles-grid similar_articles row">
        {articlesList.length &&
          articlesList.map((article) => {
            const image = getImage(article?.featuredImage);

            let category;
            if (
              article?.categories !== null ||
              article?.categories !== undefined
            ) {
              category = article?.categories[0]?.toLowerCase();
            } else category = "";

            let categoryLink;
            if (category === "promotions") {
              categoryLink = "campaigns";
            } else {
              categoryLink = category;
            }
            return (
              <div
                className="articles-grid__item col-12 col-xl-3 col-md-4"
                key={article?.title}
              >
                <article className="card article-card card--article post-12076 post type-post status-publish format-standard has-post-thumbnail hentry category-kampanjat">
                  <GatsbyImage image={image} alt={title} />
                  <div className="card-body">
                    <header>
                      <h2 className="entry-title card-title">
                        <Link
                          to={`/${categoryLink.replace(" ", "-")}/${
                            article?.slug
                          }/`}
                          class="stretched-link"
                        >
                          {article?.title}
                        </Link>
                      </h2>
                    </header>
                    <footer>
                      <div className="article-card__meta entry-meta">
                        <time className="updated">{article?.createdData}</time>
                        <div className="categories">
                          {article?.categories[0]}
                        </div>
                      </div>
                    </footer>
                  </div>
                </article>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default SimilarArticles;
