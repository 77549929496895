import React, { memo } from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

const CasinoCard = memo(
  ({
    casinoName,
    logo,
    backgroundColor,
    description,
    slug,
    categories,
    referralUrl,
    cardSize,
    rating,
    inView,
  }) => {
    const image = getImage(logo);
    const category = categories?.toLowerCase();
    function roundHalf(num) {
      return Math.round(num * 2) / 2;
    }
    let cardWrapperClassName;
    const casinoRounded = roundHalf(rating);

    cardSize === "large"
      ? (cardWrapperClassName = "col-6 col-sm-6 col-lg-4 col-xl-3")
      : (cardWrapperClassName = "col-6 col-sm-6 col-lg-4 col-xl-2");
    return (
      <div
        className={`casino-columns__item casino-columns__item--popular ${cardWrapperClassName}`}
      >
        <div className="casino-card card casino-card--light">
          {inView ? (
            <div className="casino-card__image card-img">
              <Link to={`/${category}/${slug}/`} class="casino-card__link">
                <GatsbyImage
                  image={image}
                  alt={casinoName}
                  imgStyle={{ zIndex: "1" }}
                />
                <div
                  className="casino-card__bg"
                  style={{ backgroundColor }}
                ></div>
              </Link>
            </div>
          ) : (
            <div className="casino-card__image card-img" />
          )}

          <div className="casino-card__content card-body">
            <div className="casino-card__title ">
              <Link to={`/${category}/${slug}/`} class="casino-card__link">
                {casinoName}
              </Link>
            </div>
            <div className="card-rating-wrapper">
              <div className="top-casino-rating casino-hero-rating review-rating">
                <p>★</p>
                <span>{casinoRounded}/10</span>
              </div>
            </div>
            <div className="casino-card__text">{description}</div>

            <a
              href={referralUrl}
              className="btn btn--play-now btn-primary btn-sm btn-block card-link"
              target="_blank"
              rel="noopener sponsored noreferrer"
              data-list="DZADARATBEBOBABRCACLCOCZECEGFIDEHUISINIEJPKZLILVLTLUMTMXMCMANLNZNOPYPEQARSSKSICHAEUYUZ"
            >
              Play now
            </a>
          </div>
        </div>
      </div>
    );
  }
);

const CasinosList = ({ title, casinosCadsData, cardSize }) => {
  const [ref, inView] = useInView({
    rootMargin: "-100px",
    triggerOnce: true,
  });
  return (
    <section className="casino-section" ref={ref}>
      <div className="popular-casinos casino-columns">
        {title && (
          <h2 className="popular-casinos__title casino-section__title">
            {title}
          </h2>
        )}
        <div className="row casino-columns__list">
          {casinosCadsData.length &&
            casinosCadsData.map((casinoItem) => {
              return (
                <CasinoCard
                  key={casinoItem?.casinoName}
                  casinoName={casinoItem?.casinoName}
                  description={casinoItem?.shortDescription}
                  referralUrl={casinoItem?.referralUrl}
                  backgroundColor={casinoItem?.logoBackgroundColor}
                  logo={casinoItem?.logo}
                  slug={casinoItem?.slug}
                  categories={casinoItem?.categories}
                  cardSize={cardSize}
                  rating={casinoItem?.rating}
                  inView={inView}
                />
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default CasinosList;
