import React from "react";
import { graphql } from "gatsby";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import Layout from "../components/Layout";
import BlogHero from "../components/BlogHero";
import ContentComponent from "../components/ContentComponent";
import SimilarArticles from "../components/SimilartArticles";
import CasinosList from "../components/CasinosList";
import Seo from "../components/Seo";

const BlogPostPage = ({ data, location }) => {
  const pageData = data?.contentfulBlogPost;
  const shareUrl = `https://list.casino/${location.pathname}`;

  return (
    <Layout>
      <Seo
        title={pageData?.seoComponent?.seoTitle}
        description={pageData?.seoComponent?.seoDescription}
        url={`https://list.casino/${pageData?.categories[0].toLowerCase()}/${
          pageData?.seoComponent?.seoSlug
        }/`}
        canonical={`https://list.casino/${pageData?.categories[0].toLowerCase()}/${
          pageData?.seoComponent?.seoSlug
        }/`}
      />
      <div className="wrap">
        <div className="content container">
          <article className="single-post post-7809 post type-post status-publish">
            <BlogHero
              title={pageData?.title}
              createdDate={pageData?.createdData}
              featuredImage={pageData?.featuredImage}
              category={pageData?.categories[0]}
            />
            <div className="row">
              <div className="col-lg-8 col-md-9 col-12 order-1 order-lg-2 order-md-2">
                <ContentComponent
                  data={pageData?.description?.childMarkdownRemark?.html}
                />
                {pageData?.relatedCasinosList && (
                  <CasinosList
                    title={pageData?.relatedCasinosList?.title}
                    casinosCadsData={pageData?.relatedCasinosList.casinosList}
                    marginTop={pageData.relatedCasinosList.marginTop}
                    marginBottom={pageData.relatedCasinosList.marginBottom}
                    cardSize="large"
                  />
                )}
                <ContentComponent
                  data={pageData?.blogBody?.childMarkdownRemark?.html}
                />
                {pageData?.affiliateLink && (
                  <a
                    href={pageData?.affiliateLink}
                    className="btn btn--play-now btn-sm btn-primary btn-block affiliate-link"
                    target="_blank"
                    rel="noopener sponsored noreferrer"
                  >
                    Claim Offer
                  </a>
                )}
              </div>
              <div className="col-lg-2 col-md-3 col-12 order-2 order-md-1 order-lg-1">
                <div className="single-post__sharing">
                  <h2>Share via:</h2>

                  <ul className="social-media social-media--vertical">
                    <li className="social-media__item social-media__item--facebook">
                      <FacebookShareButton url={shareUrl}>
                        <a>Facebook</a>
                      </FacebookShareButton>
                    </li>
                    <li className="social-media__item social-media__item--twitter">
                      <TwitterShareButton url={shareUrl}>
                        <a>Twitter</a>
                      </TwitterShareButton>
                    </li>
                    <li className="social-media__item social-media__item--linkedin">
                      <LinkedinShareButton url={shareUrl}>
                        <a>LinkedIn</a>
                      </LinkedinShareButton>
                    </li>
                  </ul>
                  {pageData.relatedCasinos &&
                  pageData.relatedCasinos[0]?.bonuses !== null ? (
                    <div className="single-post__related-casino">
                      <div className="card card--casino-welcome-bonus">
                        <div className="card-body">
                          {pageData?.relatedCasinos[0]?.bonuses && (
                            <p>
                              {
                                pageData?.relatedCasinos[0]?.bonuses[0]
                                  ?.description
                              }
                            </p>
                          )}
                          <p>
                            <a
                              href={
                                pageData?.relatedCasinos[0]?.bonuses[0]
                                  ?.referralUrl
                              }
                              className="btn btn--play-now btn-sm btn-primary btn-block"
                              target="_blank"
                              rel="noopener sponsored noreferrer"
                              data-list="DZADARATBEBOBABRCACLCOCZECEGFIDEHUISINIEJPKZLILVLTLUMTMXMCMANLNZNOPYPEQARSSKSICHAEUYUZ"
                            >
                              Get started now!
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </article>
          {pageData?.similarArticles && (
            <SimilarArticles
              title="Samanlaiset julkaisut"
              articlesList={pageData?.similarArticles}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default BlogPostPage;

export const query = graphql`
  query post($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      id
      slug
      categories
      affiliateLink
      blogBody {
        childMarkdownRemark {
          html
        }
      }
      featuredImage {
        gatsbyImage(
          width: 800
          quality: 100
          placeholder: NONE
          formats: [AUTO, WEBP]
        )
      }
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      seoComponent {
        seoTitle
        seoDescription
        seoSlug
      }
      createdData(formatString: "DD.MM.YYYY")
      relatedCasinos {
        bonuses {
          name
          slug
          description
          code
          referralUrl
          partnersTermsAndConditionalLink
        }
      }
      relatedCasinosList {
        title
        marginTop
        marginBottom
        casinosList {
          casinoName
          shortDescription
          logoBackgroundColor
          slug
          categories
          referralUrl
          rating
          logo {
            gatsbyImage(
              width: 120
              quality: 100
              placeholder: NONE
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`;

/*
similarArticles {
  id
  slug
  createdData(formatString: "DD.MM.YYYY")
  categories
  title
  featuredImage {
    gatsbyImageData
  }
}

*/
